import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

let promise;

/**
 * Creates a wrapper around the dispatch function that will call the given action creator.
 * Subsequent calls made by the wrapper function that is returned will abort the previous thunked action creator
 * which in turn will cause the api call that was in-flight to cancel.
 *
 * The wrapper function returned will be a stable function wrapped in useCallback that will only update if the
 * given action creator is changed.
 * @param {Function} actionCreator The action creator to be dispatched
 * @returns A wrapper around the dispatch function that will call the given action creator.
 */
export const useNetworkCall = (actionCreator, isCancelable = true) => {
  const dispatch = useDispatch();

  return useCallback(
    /**
     * A stable wrapper around the dispatch function that accepts a payload and returns the unwrapped promise.
     * The function reference will only update if the action creator is changed.
     * @param payload Optional payload to be passed to the action creator.
     * @returns {Promise} The unwrapped promise returned from the dispatch function.
     */
    (payload) =>
      new Promise((resolve, reject) => {
        // Abort last request
        // Using setTimeout to ensure the aborted request's rejected action calls before this so pending happens after rejected.
        if (promise && isCancelable) {
          promise.abort();
        }
        setTimeout(() => {
          resolve();
        });
      }).then(() => {
        // Dispatch the new request.
        promise = dispatch(actionCreator(payload));
        return promise.unwrap();
      }),
    [actionCreator, dispatch, isCancelable]
  );
};
