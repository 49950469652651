import { useSelector } from 'react-redux';
import { useNetworkCall } from '../../app/hooks/useNetworkCall';
import {
  searchLogs,
  selectLastPageRemainder,
  selectLogs,
  selectPageSize,
  selectTotalPages
} from '../../features/log/logSlice';
import Pagination from '../Pagination/Pagination';
import FilterIcon from '../../shared/icons/FilterIcon';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip
} from 'reactstrap';
import { useState } from 'react';
import FiltersModal from '../FiltersModal/FiltersModal';
import GearIcon from '../../shared/icons/GearIcon';
import BundleCheckModal from '../BundleCheckModal/BundleCheckModal';
import EnableLineNumbersModal from '../EnableLineNumbersModal/EnableLineNumbersModal';

const LogTable = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [acTooltipOpen, setAcTooltipOpen] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterNumber, setFilterNumber] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showBundleCheckModal, setShowBundleCheckModal] = useState(false);
  const [showEnableLineNumbersModal, setShowEnableLineNumbersModal] =
    useState(false);
  const logs = useSelector(selectLogs);
  const totalPages = useSelector(selectTotalPages);
  const lastPageRemainder = useSelector(selectLastPageRemainder);
  const pageSize = useSelector(selectPageSize);
  const networkSearchLogs = useNetworkCall(searchLogs);

  return (
    <div className='LogTable mt-4'>
      <div className='d-flex'>
        <span className='me-2 z-1'>
          <button
            id='filterButton'
            className='btn btn-light position-relative'
            onClick={() => setShowFilterModal(!showFilterModal)}
          >
            <FilterIcon />
            {filterNumber ? (
              <span className='badge bg-primary position-absolute'>
                {filterNumber}
              </span>
            ) : null}
          </button>
          <Tooltip
            target='filterButton'
            isOpen={tooltipOpen}
            toggle={() => setTooltipOpen(!tooltipOpen)}
          >
            Filter & Sort
          </Tooltip>
        </span>
        <span className='me-auto'>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle id='advancedConfigButton' caret color='light'>
              <GearIcon />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Publishing</DropdownItem>
              <DropdownItem
                onClick={() => setShowBundleCheckModal(!showBundleCheckModal)}
              >
                Publish Status
              </DropdownItem>
              <DropdownItem header>Callback Logs</DropdownItem>
              <DropdownItem
                onClick={() =>
                  setShowEnableLineNumbersModal(!showEnableLineNumbersModal)
                }
              >
                Enable line numbers
              </DropdownItem>
              <DropdownItem header>Export</DropdownItem>
              <DropdownItem disabled onClick={console.log}>Excel</DropdownItem>
              <DropdownItem disabled onClick={console.log}>JSON</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Tooltip
            target='advancedConfigButton'
            isOpen={acTooltipOpen}
            toggle={() => setAcTooltipOpen(!acTooltipOpen)}
          >
            Advanced Configuration
          </Tooltip>
        </span>
        <Pagination
          pages={totalPages}
          onPageChange={page =>
            networkSearchLogs({
              PageNumber: page,
              PageSize: page === totalPages ? lastPageRemainder : pageSize,
              SortOrder: 'ASC'
            })
          }
        />
      </div>
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>Timestamp (UTC)</th>
              <th>User ID</th>
              <th>Level</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {logs.length > 0 ? (
              logs.map(({ Message }, index) => (
                <tr key={index}>
                  <td className='text-nowrap'>{Message.Timestamp}</td>
                  <td>{Message.UserId}</td>
                  <td>{Message.LogLevel}</td>
                  <td>{Message.AppMessage}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className='mx-5 p-5 bg-light text-center'>
                  No logs loaded
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <FiltersModal
        showModal={showFilterModal}
        toggleModal={() => setShowFilterModal(!showFilterModal)}
        onFilterNumberChange={setFilterNumber}
      />
      <BundleCheckModal
        showModal={showBundleCheckModal}
        toggleModal={() => setShowBundleCheckModal(!showBundleCheckModal)}
      />
      <EnableLineNumbersModal
        showModal={showEnableLineNumbersModal}
        toggleModal={() =>
          setShowEnableLineNumbersModal(!showEnableLineNumbersModal)
        }
      />
    </div>
  );
};

export default LogTable;
