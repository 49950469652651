import AuthForm from './components/AuthForm/AuthForm';
import LogTable from './components/LogTable/LogTable';
import Header from './shared/header/Header';
//
import {BrowserRouter} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './App.css';
import { useDispatch } from 'react-redux';
import {
  authTokenChange,
  getLoggingStatus,
  instanceUrlChanged,
  usernameChanged
} from './features/log/logSlice';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import HelpModal from './components/HelpModal/HelpModal';
import { useState } from 'react';

if (!window.location.hash) {
  localStorage.clear();
}

window.jsforce.browser.init({
  clientId:
    '3MVG98XJQQAccJQcIl6s5hAC.mfyJ9zve_yri.CW.LbIbASC.eqQo6UtQGuO_.YDSCeYoMyxZk8O3CDxR_Ih_',
  redirectUri: `${window.location.origin}${window.location.pathname !== '/' ? window.location.pathname : ''}`
});

let errorShown = false;

function App() {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const dispatch = useDispatch();

  window.jsforce.browser.on('connect', conn => {
    dispatch(
      authTokenChange(window.jsforce.browser.connection.accessToken)
    );
    conn.query(
      `SELECT Id, Name FROM User WHERE Id = '${window.jsforce.browser.connection.userInfo.id}'`,
      function (err, res) {
        if (err && !errorShown) {
          toast.error('Unable to fetch Instance URL data. Be sure to add a ' + window.location.origin + ' to the allowed origins whitelist in your Salesforce org\'s CORS settings.');
          errorShown = true;
        }
        if (res.records.length > 0) {
          dispatch(usernameChanged(res.records[0].Name));
          window.jsforce.browser.connection
            .query(
              'SELECT Apttus_Base2__ServiceUrl__c FROM Apttus_Base2__TurboEngineAdminProperties__c'
            )
            .then(res => {
              if (res.records.length > 0) {
                dispatch(
                  instanceUrlChanged(res.records[0].Apttus_Base2__ServiceUrl__c)
                );
                dispatch(getLoggingStatus());
              }
            })
            .catch(err => {
              console.log('err :>> ', err);
            });
        }
      }
    );
  });

  return (
  //Added
    <BrowserRouter basename='/tlogs'>
    <div className='App'>
      <ErrorBoundary>
        <Header onHelpClick={() => setShowHelpModal(!showHelpModal)} />
      </ErrorBoundary>
      <div className='mainContent pt-3'>
        <ErrorBoundary>
          <AuthForm />
        </ErrorBoundary>
        <ErrorBoundary>
          <LogTable />
        </ErrorBoundary>
      </div>
      <ToastContainer
        position='top-center'
        theme='colored'
        pauseOnFocusLoss={false}
      />
      <HelpModal
        showModal={showHelpModal}
        toggleModal={() => setShowHelpModal(!showHelpModal)}
      />
    </div>
    </BrowserRouter>
  );
}

export default App;
