import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import {
  filtersChanged,
  searchLogs,
  selectPageSize
} from '../../features/log/logSlice';
import './FiltersModal.css';
import { useSinceTimeToDateTime } from '../../app/hooks/useSinceTimeToDateTime';

const FiltersModal = props => {
  const [AppMessage, setAppMessage] = useState('');
  const [CartId, setCartId] = useState('');
  const [ClassName, setClassName] = useState('');
  const [CorrelationId, setCorrelationId] = useState('');
  const [LogLevel, setLogLevel] = useState('Trace');
  const [ServiceName, setServiceName] = useState('All');
  const [StartTime, setStartTime] = useState('');
  const [EndTime, setEndTime] = useState('');
  const [IsExtensibilityLog, setIsExtensibilityLog] = useState(false);
  const [UseTenantId, setUseTenantId] = useState(false);
  const [SortOrder, setSortOrder] = useState('ASC');
  const [sinceTime, setSinceTime] = useState('');

  const dispatch = useDispatch();
  const sinceTimeToDatetime = useSinceTimeToDateTime();
  const pageSize = useSelector(selectPageSize);

  const handleApply = () => {
    const filters = {
      ...(AppMessage !== '' && { AppMessage }),
      ...(CartId !== '' && { CartId }),
      ...(ClassName !== '' && { ClassName }),
      ...(CorrelationId !== '' && { CorrelationId }),
      ...(StartTime !== '' && { StartTime }),
      ...(EndTime !== '' && { EndTime }),
      ...(ServiceName !== 'All' && { ServiceName }),
      LogLevel,
      IsExtensibilityLog,
      UseTenantId,
      SortOrder
    };

    // Subtract one so sort order doesn't get counted
    let numberOfFilters = Object.keys(filters).length - 1;
    if (LogLevel === 'Trace') numberOfFilters--;
    if (!IsExtensibilityLog) numberOfFilters--;
    if (!UseTenantId) numberOfFilters--;
    props.onFilterNumberChange(numberOfFilters);

    dispatch(filtersChanged(filters));

    dispatch(
      searchLogs({
        ...filters,
        PageNumber: 1,
        PageSize: pageSize
      })
    );
    props.toggleModal();
  };

  const resetForm = () => {
    setAppMessage('');
    setCartId('');
    setClassName('');
    setCorrelationId('');
    setLogLevel('Trace');
    setServiceName('All');
    setStartTime('');
    setEndTime('');
    setIsExtensibilityLog(false);
    setUseTenantId(false);
    setSortOrder('ASC');
    setSinceTime('');
  };

  return (
    <Modal className='FiltersModal' isOpen={props.showModal} centered size='xl'>
      <ModalHeader toggle={props.toggleModal}>Filter & Sort</ModalHeader>
      <ModalBody>
        <div className='row mb-3'>
          <h5>Sorting</h5>
          <div className='col-md-6'>
            <select
              className='form-select'
              value={SortOrder}
              onChange={event => setSortOrder(event.target.value)}
            >
              <option value='ASC'>Accending</option>
              <option value='DESC'>Descending</option>
            </select>
          </div>
        </div>
        <div className='row'>
          <h5>Filters</h5>
          <div className='col-md-6 my-2'>
            <label className='form-label' htmlFor='appMessage'>
              App Message
            </label>
            <input
              className='form-control'
              type='text'
              id='appMessage'
              value={AppMessage}
              onChange={event => setAppMessage(event.target.value)}
            />
          </div>
          <div className='col-md-6 my-2'>
            <label className='form-label' htmlFor='cartId'>
              Cart Id
            </label>
            <input
              className='form-control'
              type='text'
              id='cartId'
              value={CartId}
              onChange={event => setCartId(event.target.value)}
            />
          </div>
          <div className='col-md-6 my-2'>
            <label className='form-label' htmlFor='className'>
              Class Name
            </label>
            <input
              className='form-control'
              type='text'
              id='className'
              value={ClassName}
              onChange={event => setClassName(event.target.value)}
            />
          </div>
          <div className='col-md-6 my-2'>
            <label className='form-label' htmlFor='correlationId'>
              Correlation Id
            </label>
            <input
              className='form-control'
              type='text'
              id='correlationId'
              value={CorrelationId}
              onChange={event => setCorrelationId(event.target.value)}
            />
          </div>
          <div className='col-md-6 my-2'>
            <label className='form-label'>Log Level</label>
            <select
              className='form-select'
              value={LogLevel}
              onChange={event => setLogLevel(event.target.value)}
            >
              <option value='Trace'>Trace</option>
              <option value='Debug'>Debug</option>
              <option value='Information'>Information</option>
              <option value='Warning'>Warning</option>
              <option value='Error'>Error</option>
              <option value='Critical'>Critical</option>
            </select>
          </div>
          <div className='col-md-6 my-2'>
            <label className='form-label' htmlFor='serviceName'>
              Service Name
            </label>
            <select
              className='form-select'
              value={ServiceName}
              onChange={event => setServiceName(event.target.value)}
            >
              <option value='All'>All</option>
              <option value='Pricing-Web'>Pricing-Web</option>
              <option value='Pricing-Cart'>Pricing-Cart</option>
              <option value='Pricing-DataAdmin'>Pricing-DataAdmin</option>
            </select>
          </div>
          <div className='col-md-6 my-2'>
            <label className='form-label' htmlFor='sinceTime'>
              Since Time
            </label>
            <InputGroup>
              <select
                id='sinceTime'
                className={`form-select ${
                  sinceTime === '' && 'text-secondary'
                }`}
                value={sinceTime}
                onChange={event => {
                  setSinceTime(event.target.value);
                  setStartTime(sinceTimeToDatetime(event.target.value));
                  setEndTime('');
                }}
              >
                <option className='text-secondary' selected disabled value=''>
                  Make Selection . . .
                </option>
                <option value='5 minutes'>5 Minutes</option>
                <option value='15 minutes'>15 Minutes</option>
                <option value='30 minutes'>30 Minutes</option>
                <option value='1 hour'>1 hour</option>
                <option value='2 hours'>2 hours</option>
                <option value='3 hours'>3 hours</option>
                <option value='6 hours'>6 hours</option>
                <option value='12 hours'>12 hours</option>
                <option value='1 day'>1 day</option>
                <option value='2 days'>2 days</option>
                <option value='3 days'>3 days</option>
                <option value='4 days'>4 days</option>
              </select>
              {sinceTime !== '' && (
                <button
                  className='btn btn-secondary'
                  onClick={() => setSinceTime('')}
                >
                  Clear
                </button>
              )}
            </InputGroup>
          </div>
          <div className='col-12 my-2'>
            <p>Note: All times entered should be in UTC</p>
          </div>
          <div className='col-md-6'>
            <label className='form-label' htmlFor='startTime'>
              Start Time
            </label>
            <input
              className='form-control'
              type='datetime-local'
              id='startTime'
              disabled={sinceTime !== ''}
              value={StartTime}
              onChange={event => setStartTime(event.target.value)}
            />
          </div>
          <div className='col-md-6 my-2'>
            <label className='form-label' htmlFor='endTime'>
              End Time
            </label>
            <input
              className='form-control'
              type='datetime-local'
              id='endTime'
              disabled={sinceTime !== ''}
              value={EndTime}
              onChange={event => setEndTime(event.target.value)}
            />
          </div>
          <div className='col-md-6 my-2 form-check form-switch'>
            <label className='form-check-label' htmlFor='isExtensibilityLog'>
              Is Extensibility Log
            </label>
            <input
              className='form-check-input'
              type='checkbox'
              id='isExtensibilityLog'
              checked={IsExtensibilityLog}
              onChange={event => setIsExtensibilityLog(event.target.checked)}
            />
          </div>
          <div className='col-md-6 my-2 form-check form-switch'>
            <label className='form-check-label' htmlFor='useTenantId'>
              Use Tenant Id
            </label>
            <input
              className='form-check-input'
              type='checkbox'
              id='useTenantId'
              checked={UseTenantId}
              onChange={event => setUseTenantId(event.target.checked)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn' onClick={props.toggleModal}>
          Cancel
        </button>
        <button className='btn btn-outline-primary' onClick={resetForm}>
          Reset
        </button>
        <button className='btn btn-primary' onClick={handleApply}>
          Apply
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default FiltersModal;
