import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialState = {
  isProductDirty: false,
  publishStatusLoading: false,
  publishStatus: null
};

export const getPublishStatus = createAsyncThunk(
  'publish/getPublishStatus',
  async (payload, { getState, signal, dispatch }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const { authToken, instanceUrl } = getState().log;

    const response = await axios.get(
      `${instanceUrl}/pricing/cmspublish/publishstatus?productId=${payload.productId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        cancelToken: source.token
      }
    );
    dispatch(productDirtyChanged(response.data?.IsProductDirty));
    return { headers: response.headers, data: response.data };
  }
);

export const publishProduct = createAsyncThunk(
  'publish/publishProduct',
  async (payload, { getState, signal, dispatch }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const { authToken, instanceUrl } = getState().log;

    const response = await axios.post(
      `${instanceUrl}/pricing/cmspublish/publishbundle`,
      {
        ...payload
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        cancelToken: source.token
      }
    );
    dispatch(getPublishStatus({ productId: payload.hierarchyId[0] }));
    return { headers: response.headers, data: response.data };
  }
);

export const publishSlice = createSlice({
  name: 'publish',
  initialState,
  reducers: {
    productDirtyChanged: (state, action) => {
      state.isProductDirty = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getPublishStatus.pending, state => {
        state.publishStatusLoading = true;
      })
      .addCase(getPublishStatus.fulfilled, (state, action) => {
        state.publishStatusLoading = false;
        state.publishStatus = action.payload.data;
      })
      .addCase(getPublishStatus.rejected, (state, action) => {
        state.publishStatusLoading = false;
        state.publishStatus = null;
        toast.error(
          <div>
            Houston, we have a problem.
            <br />
            {action.error.message}
          </div>
        );
      })
});

export const selectPublishStatus = state => state.publish.publishStatus;
export const selectIsProductDirty = state => state.publish.isProductDirty;

export const { productDirtyChanged } = publishSlice.actions;

export default publishSlice.reducer;
