import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import logReducer from '../features/log/logSlice';
import publishReducer from '../features/publish/publishSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    log: logReducer,
    publish: publishReducer,
  },
});
