import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  logoutUser,
  selectLoadingLogout,
  selectUsername
} from '../../features/log/logSlice';
import congaLogo from './conga-logo.svg';
import './Header.css';
import HelpIcon from './HelpIcon/HelpIcon';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

const Header = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [customLoginDomain, setCustomLoginDomain] = useState('');
  const username = useSelector(selectUsername);
  const loadingLogout = useSelector(selectLoadingLogout);
  const dispatch = useDispatch();

  const handleLogin = loginUrl => {
    window.jsforce.browser.login({ loginUrl });
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <header className='Header d-flex align-items-center border-bottom'>
      <img className='congaLogo me-2' src={congaLogo} alt='Conga Logo' />
      <h5 className='m-0'>Tlogs</h5>
      {username ? (
        <div className='ms-auto'>
          <span className='me-3'>{username}</span>
          <button
            className='loginButton position-relative'
            onClick={handleLogout}
          >
            <span
              className={`link-primary ${loadingLogout ? 'invisible' : ''}`}
            >
              Log Out
            </span>
            <div
              className={`spinner-border spinner-border-sm text-primary position-absolute ${
                !loadingLogout ? 'invisible' : ''
              }`}
              role='status'
              style={{ left: '33%', top: '20%' }}
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
          </button>
        </div>
      ) : (
        <Dropdown
          className='ms-auto'
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen(prev => !prev)}
        >
          <DropdownToggle tag='span' className='loginButton link-primary' caret>
            Login
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => handleLogin('https://login.salesforce.com')}
            >
              login.salesforce.com
            </DropdownItem>
            <DropdownItem
              onClick={() => handleLogin('https://test.salesforce.com')}
            >
              test.salesforce.com
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => setShowModal(prev => !prev)}>
              Custom Domain
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      <button className='loginButton ms-5' onClick={props.onHelpClick}>
        <span className='link-primary'>
          Help <HelpIcon />
        </span>
      </button>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(prev => !prev)}
        centered
      >
        <ModalHeader toggle={() => setShowModal(prev => !prev)}>
          Custom Login Domain
        </ModalHeader>
        <ModalBody>
          <Label for='modalInput'>Enter Custom Login Domain</Label>
          <Input
            id='modalInput'
            placeholder='https://custom-domain.org'
            value={customLoginDomain}
            onChange={e => setCustomLoginDomain(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <button
            className='btn'
            onClick={() => {
              setShowModal(prev => !prev);
              setCustomLoginDomain('');
            }}
          >
            Cancel
          </button>
          <button
            className='btn btn-primary'
            onClick={() => handleLogin(customLoginDomain)}
          >
            Login
          </button>
        </ModalFooter>
      </Modal>
    </header>
  );
};

export default Header;
