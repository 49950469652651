import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SF from './sf.jpg';
import creds from './creds.jpg';
import cors from './cors.jpg';

const HelpModal = ({ showModal, toggleModal }) => {
  return (
    <Modal isOpen={showModal} size='lg' toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Using TLogs (version {process.env.REACT_APP_VERSION})</ModalHeader>
      <ModalBody className='d-flex flex-column'>
        <h5>Setup Guide:</h5>
        <p className='ms-5'>
          1. Whitelist <i>"https://tools.congacloud.io"</i> in your salesforce org under <i>Setup</i> -&gt; <i>Security</i> -&gt; <i>CORS</i>.
        </p>
        <img
          className='align-self-center'
          src={cors}
          alt='cors setup'
          style={{ height: '300px', width: 'fit-content' }}
        />
        <h5 className='mt-3'>Usage Guide:</h5>
        <p className='ms-5'>
          1. Click on <b>Login</b> in top right header to SSO to Salesforce org and allow access.
        </p>
        <img
          className='align-self-center'
          src={SF}
          alt='salesforce login'
          style={{ height: '300px', width: 'fit-content' }}
        />
        <p className='ms-5 mt-3'>
          Or enter Instance URL and Auth Token (do not prefix with <b>Bearer</b> it will
          be added automatically).
        </p>
        <img
          className='align-self-center'
          src={creds}
          alt='enter credentials'
          style={{ width: '80%' }}
        />
        <p className='ms-5'>
          2. If the logs are already enabled, view logs either by clicking{' '}
          <b>Get Logs</b> or <b>Filter</b> logs where you can also apply
          filters.
        </p>
        <p className='ms-5'>
          3. Click on <b>Disable Logging</b> to turn off logging.
        </p>
      </ModalBody>
    </Modal>
  );
};

export default HelpModal;
