import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { enableLineNumbers } from '../../features/log/logSlice';

const EnableLineNumbersModal = props => {
  const [projectName, setProjectName] = useState('');
  const dispatch = useDispatch();

  const handleEnable = async () => {
    const result = await dispatch(enableLineNumbers({ name: projectName }));
    if (result.meta.requestStatus === 'fulfilled') {
      props.toggleModal();
    }
  };

  return (
    <Modal className='BundleCheckModal' isOpen={props.showModal} centered>
      <ModalHeader toggle={props.toggleModal}>
        Enable Line Numbers For Callback Logs
        <small
          style={{
            display: 'block',
            fontWeight: 'normal',
            fontSize: '.875rem'
          }}
        >
          Enable line numbers in the logs for a specific callback given its
          project name.
        </small>
      </ModalHeader>
      <ModalBody>
        <label className='form-label' htmlFor='projectName'>
          Project Name
        </label>
        <input
          className='form-control'
          type='text'
          id='projectName'
          value={projectName}
          onChange={event => setProjectName(event.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <button className='btn' onClick={props.toggleModal}>
          Cancel
        </button>
        <button
          className='btn btn-primary'
          onClick={handleEnable}
          disabled={!projectName}
        >
          Enable
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default EnableLineNumbersModal;
