import { useCallback } from 'react';

const SINCE_TIME_TO_DATETIME_MAP = {
  '5 minutes': () => new Date(Date.now() - 5 * 60000).toISOString(),
  '15 minutes': () => new Date(Date.now() - 15 * 60000).toISOString(),
  '30 minutes': () => new Date(Date.now() - 30 * 60000).toISOString(),
  '1 hour': () => new Date(Date.now() - 60 * 60000).toISOString(),
  '2 hours': () => new Date(Date.now() - 120 * 60000).toISOString(),
  '3 hours': () => new Date(Date.now() - 180 * 60000).toISOString(),
  '6 hours': () => new Date(Date.now() - 360 * 60000).toISOString(),
  '12 hours': () => new Date(Date.now() - 720 * 60000).toISOString(),
  '1 day': () => new Date(Date.now() - 24 * 60 * 60000).toISOString(),
  '2 days': () => new Date(Date.now() - 2 * 24 * 60 * 60000).toISOString(),
  '3 days': () => new Date(Date.now() - 3 * 24 * 60 * 60000).toISOString(),
  '4 days': () => new Date(Date.now() - 4 * 24 * 60 * 60000).toISOString()
};

export const useSinceTimeToDateTime = () =>
  useCallback(sinceTime => SINCE_TIME_TO_DATETIME_MAP[sinceTime](), []);
