import { useState } from 'react';
import './Pagination.css';
import { useDispatch, useSelector } from 'react-redux';
import { pageSizeChanged, selectPageSize } from '../../features/log/logSlice';

const Pagination = ({ pages, onPageChange }) => {
  const [page, setPage] = useState(1);
  const pageSize = useSelector(selectPageSize);
  const dispatch = useDispatch();

  const getPaginationArray = (() => {
    const array = [...new Array(pages + 1).keys()].slice(1);
    if (pages <= 5) {
      return array;
    } else if (page <= 3) {
      return array.slice(0, 5);
    } else if (page > 3 && page <= pages - 3) {
      return array.slice(page - 3, page + 2);
    } else if (page > pages - 3) {
      return array.slice(pages - 5, pages);
    }
  })();

  return (
    <div className='Pagination d-flex justify-content-end'>
      <nav className='me-2' aria-label='Page navigation example'>
        <ul className='pagination justify-content-center mb-0'>
          {getPaginationArray[0] > 1 && (
            <li className='page-item'>
              <button
                className='page-link'
                tabIndex='1'
                onClick={() => {
                  setPage(1);
                  onPageChange(1);
                }}
              >
                First
              </button>
            </li>
          )}
          <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
            <button
              className='page-link'
              tabIndex={page === 1 ? '-1' : '1'}
              aria-disabled={page === 1 ? 'true' : 'false'}
              onClick={() => {
                setPage(page - 1);
                onPageChange(page - 1);
              }}
            >
              Previous
            </button>
          </li>
          {getPaginationArray[0] > 1 && (
            <span className='align-self-end mx-1'>...</span>
          )}
          {getPaginationArray.map((e, index) => (
            <li
              className={`page-item ${page === e ? 'active' : ''}`}
              key={index}
            >
              <button
                className='page-link'
                onClick={() => {
                  setPage(e);
                  onPageChange(e);
                }}
              >
                {e}
              </button>
            </li>
          ))}
          {getPaginationArray[getPaginationArray.length - 1] < pages && (
            <span className='align-self-end mx-1'>...</span>
          )}
          <li className={`page-item ${page === pages ? 'disabled' : ''}`}>
            <button
              className='page-link'
              tabIndex={page === pages ? '-1' : '1'}
              aria-disabled={page === pages ? 'true' : 'false'}
              onClick={() => {
                setPage(page + 1);
                onPageChange(page + 1);
              }}
            >
              Next
            </button>
          </li>
          {getPaginationArray[getPaginationArray.length - 1] < pages && (
            <li className='page-item'>
              <button
                className='page-link'
                tabIndex='1'
                onClick={() => {
                  setPage(pages);
                  onPageChange(pages);
                }}
              >
                Last
              </button>
            </li>
          )}
        </ul>
      </nav>
      <div className='d-flex align-items-center'>
        <label className='form-label mb-0 me-2' htmlFor='pageSize'>
          Page Size
        </label>
        <input
          id='pageSize'
          className='form-control pageSizeInput'
          type='number'
          step={1}
          min={1}
          value={pageSize}
          onChange={event => dispatch(pageSizeChanged(event.target.value))}
        />
      </div>
    </div>
  );
};

export default Pagination;
