import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  getPublishStatus,
  publishProduct,
  selectIsProductDirty,
  selectPublishStatus
} from '../../features/publish/publishSlice';
import { useNetworkCall } from '../../app/hooks/useNetworkCall';

const BundleCheckModal = props => {
  const [productId, setProductId] = useState('');
  const dispatch = useDispatch();
  const networkGetPublishStatus = useNetworkCall(getPublishStatus);
  const networkPublishProduct = useNetworkCall(publishProduct);
  const publishStatus = useSelector(selectPublishStatus);
  const isProductDirty = useSelector(selectIsProductDirty);


  const handleCheckStatus = () => {
    dispatch(
      networkGetPublishStatus({
        productId
      })
    );
  };

  const handlePublish = () => {
    dispatch(
      networkPublishProduct({
        hierarchyId: [`${productId}`]
      })
    );
  };

  return (
    <Modal
      className='BundleCheckModal'
      isOpen={props.showModal}
      centered
      size='xl'
    >
      <ModalHeader toggle={props.toggleModal}>
        Bundle Publish Status Check
        <small
          style={{
            display: 'block',
            fontWeight: 'normal',
            fontSize: '.875rem'
          }}
        >
          Check the publish status of a bundle or product.
        </small>
      </ModalHeader>
      <ModalBody>
        <div className='col-md-6'>
          <label className='form-label' htmlFor='appMessage'>
            Product Id
          </label>
          <input
            className='form-control'
            type='text'
            id='productId'
            value={productId}
            onChange={event => setProductId(event.target.value)}
          />
        </div>
        {publishStatus && (
          <div className='col-12'>
            <h5 className='py-3'>Result</h5>
            <pre className='p-4 bg-light'>
              {JSON.stringify(publishStatus, 0, 2)}
            </pre>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button className='btn' onClick={props.toggleModal}>
          Cancel
        </button>
        {isProductDirty && (
          <button className='btn btn-outline-primary' onClick={handlePublish}>
            Publish
          </button>
        )}
        <button
          className='btn btn-primary'
          onClick={handleCheckStatus}
          disabled={!productId}
        >
          Check Status
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default BundleCheckModal;
