import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  postLogRequest,
  selectLoadingStartLogs
} from '../../features/log/logSlice';

const getUTCDatetime = (minutesOffset = 0, referenceTime) => {
  let datetime;
  if (referenceTime) {
    const [year, month, day, hours, minutes, seconds] =
      referenceTime.split(/[-T:]/);
    datetime = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, seconds)
    );
  } else {
    datetime = new Date();
  }
  if (minutesOffset) {
    datetime.setUTCMinutes(datetime.getUTCMinutes() + minutesOffset);
  }
  const year = datetime.getUTCFullYear();
  const month = datetime.getUTCMonth() + 1;
  const day = datetime.getUTCDate();
  const hour = datetime.getUTCHours();
  const minute = datetime.getUTCMinutes();
  const second = datetime.getUTCSeconds();
  const UTCDatetime =
    year +
    '-' +
    (month < 10 ? '0' + month.toString() : month) +
    '-' +
    (day < 10 ? '0' + day.toString() : day) +
    'T' +
    (hour < 10 ? '0' + hour.toString() : hour) +
    ':' +
    (minute < 10 ? '0' + minute.toString() : minute) +
    ':' +
    (second < 10 ? '0' + second.toString() : second);
  return UTCDatetime;
};

const EnableLoggingModal = props => {
  const [startTime, setStartTime] = useState(() => getUTCDatetime());
  const [endTime, setEndTime] = useState(() => getUTCDatetime(60));
  const [LogLevel, setLogLevel] = useState('Trace');
  const loadingStartLogs = useSelector(selectLoadingStartLogs);

  const dispatch = useDispatch();

  const handleFormSubmit = event => {
    event.preventDefault();

    dispatch(
      postLogRequest({
        StartDate: startTime,
        EndDate: endTime,
        LogLevel
      })
    ).then(res => {
      props.toggleModal();
    });
  };

  const resetForm = () => {
    setStartTime(getUTCDatetime());
    setEndTime(getUTCDatetime(60));
    setLogLevel('Trace');
  };

  return (
    <Modal className='EnableLoggingModal' isOpen={props.showModal} centered>
      <ModalHeader toggle={props.toggleModal}>Enable Logging</ModalHeader>
      <ModalBody>
        <div className='container'>
          <p>Note: All times entered should be in UTC</p>
          <form id='enableLoggingForm' onSubmit={handleFormSubmit}>
            <div className='row mb-3'>
              <label className='form-label' htmlFor='startTime'>
                Start Time
              </label>
              <input
                className='form-control'
                type='datetime-local'
                id='startTime'
                value={startTime}
                onChange={event => setStartTime(event.target.value)}
              />
            </div>
            <div className='row mb-3'>
              <label className='form-label' htmlFor='endTime'>
                End Time
              </label>
              <input
                className='form-control'
                type='datetime-local'
                id='endTime'
                max={getUTCDatetime(60 * 6, startTime)}
                value={endTime}
                onChange={event => setEndTime(event.target.value)}
              />
            </div>
            <div className='row'>
              <label className='form-label'>Log Level</label>
              <select
                className='form-select'
                value={LogLevel}
                onChange={event => setLogLevel(event.target.value)}
              >
                <option value='Trace'>Trace</option>
                <option value='Debug'>Debug</option>
                <option value='Information'>Information</option>
                <option value='Warning'>Warning</option>
                <option value='Error'>Error</option>
                <option value='Critical'>Critical</option>
              </select>
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn' onClick={props.toggleModal}>
          Cancel
        </button>
        <button className='btn btn-outline-primary' onClick={resetForm}>
          Reset
        </button>
        <button
          className='btn btn-primary'
          type='submit'
          form='enableLoggingForm'
        >
          {loadingStartLogs ? (
            <div className='position-relative'>
              <span className='invisible'>Accept</span>
              <div
                className='spinner-border spinner-border-sm text-white position-absolute'
                role='status'
                style={{ left: '33%', top: '16%' }}
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            'Accept'
          )}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default EnableLoggingModal;
