import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';

const initialState = {
  loginDomain: 'login.salesforce.com',
  instanceUrl: '',
  authToken: '',
  username: undefined,
  logRequestData: {
    Id: ''
  },
  logs: [],
  filters: {},
  loadingLogs: false,
  loadingStartLogs: false,
  loadingStopLogs: false,
  loadingLogout: false,
  totalPages: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  lastPageRemainder: 42
};

export const logoutUser = createAsyncThunk(
  'log/logoutUser',
  async (payload, { getState }) => {
    const { authToken, loginDomain } = getState().log;
    const formData = new FormData();
    formData.append('token', authToken);
    const response = await axios({
      method: 'post',
      url: `https://apttus-proxy.herokuapp.com/https://${loginDomain}/services/oauth2/revoke`,
      data: formData
    });
    return { headers: response.headers, data: response.data };
  }
);

export const getLoggingStatus = createAsyncThunk(
  'log/getLoggingStatus',
  async (payload, { getState }) => {
    const { authToken, instanceUrl } = getState().log;
    const response = await axios.get(
      `${instanceUrl}/pricing/dataadmin/logrequests`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    return { headers: response.headers, data: response.data };
  }
);

export const postLogRequest = createAsyncThunk(
  'log/postLogRequest',
  async (payload, { getState }) => {
    const { authToken, instanceUrl } = getState().log;
    await axios.post(
      `${instanceUrl}/pricing/dataadmin/logrequests`,
      {
        LogLevel: 'Trace',
        ...payload
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    const response = await axios.get(
      `${instanceUrl}/pricing/dataadmin/logrequests`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    return { headers: response.headers, data: response.data };
  }
);

export const deleteLogRequest = createAsyncThunk(
  'log/deleteLogRequest',
  async (payload, { getState }) => {
    const {
      authToken,
      instanceUrl,
      logRequestData: { Id }
    } = getState().log;
    const response = await axios.delete(
      `${instanceUrl}/pricing/dataadmin/logrequests/${Id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    return { headers: response.headers, data: response.data };
  }
);

export const searchLogs = createAsyncThunk(
  'log/searchLogs',
  async (payload, { getState, signal }) => {
    const controller = new AbortController();
    signal.addEventListener('abort', () => {
      controller.abort();
    });
    const { authToken, instanceUrl, filters } = getState().log;
    const response = await axios.post(
      `${instanceUrl}/pricing/dataadmin/logs/search`,
      { ...filters, ...payload },
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        signal: controller.signal
      }
    );
    return { headers: response.headers, data: response.data };
  }
);

export const enableLineNumbers = createAsyncThunk(
  'log/enableLineNumbers',
  async (payload, { getState }) => {
    const { authToken, instanceUrl } = getState().log;
    const response = await axios.put(
      `${instanceUrl}/pricing/api/admin/CSharpProject/enable/${payload.name}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    return { headers: response.headers, data: response.data };
  }
);

export const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    instanceUrlChanged: (state, action) => {
      state.instanceUrl = action.payload;
    },
    authTokenChange: (state, action) => {
      state.authToken = action.payload;
    },
    usernameChanged: (state, action) => {
      state.username = action.payload;
    },
    filtersChanged: (state, action) => {
      state.filters = action.payload;
    },
    loginDomainChanged: (state, action) => {
      state.loginDomain = action.payload;
    },
    pageSizeChanged: (state, action) => {
      state.pageSize = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logoutUser.pending, state => {
        state.loadingLogout = true;
      })
      .addCase(logoutUser.fulfilled, state => {
        state.loadingLogout = false;
        state.username = undefined;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loadingLogout = false;
        toast.error(
          <div>
            Houston, we have a problem.
            <br />
            {action.error.message}
          </div>
        );
      })
      .addCase(getLoggingStatus.fulfilled, (state, action) => {
        if (action.payload.data.length > 0) {
          state.logRequestData = action.payload.data[0];
        }
      })
      .addCase(getLoggingStatus.rejected, (state, action) => {
        toast.error(
          <div>
            Houston, we have a problem.
            <br />
            {action.error.message}
          </div>
        );
      })
      .addCase(postLogRequest.pending, state => {
        state.loadingStartLogs = true;
      })
      .addCase(postLogRequest.fulfilled, (state, action) => {
        state.loadingStartLogs = false;
        state.logRequestData = action.payload.data[0];
        toast.success(
          <div>
            <strong>Logging Enabled</strong>
            <br />
            Expires at: {new Date(action.payload.data[0].EndTime).toString()}
          </div>
        );
      })
      .addCase(postLogRequest.rejected, (state, action) => {
        state.loadingStartLogs = false;
        toast.error(
          <div>
            Houston, we have a problem.
            <br />
            {action.error.message}
          </div>
        );
      })
      .addCase(deleteLogRequest.pending, state => {
        state.loadingStopLogs = true;
      })
      .addCase(deleteLogRequest.fulfilled, state => {
        state.loadingStopLogs = false;
        state.logRequestData = {};
        state.logRequestData.Id = '';
        toast.info('Logging Disabled');
      })
      .addCase(deleteLogRequest.rejected, (state, action) => {
        state.loadingStopLogs = false;
        toast.error(
          <div>
            Houston, we have a problem.
            <br />
            {action.error.message}
          </div>
        );
      })
      .addCase(searchLogs.pending, state => {
        state.loadingLogs = true;
      })
      .addCase(searchLogs.fulfilled, (state, action) => {
        state.loadingLogs = false;
        state.logs = action.payload.data?.LogEntries ?? [];
        state.totalPages = Math.ceil(
          (action.payload.data?.TotalRecordsCount ?? 1) / state.pageSize
        );
        state.lastPageRemainder =
          (action.payload.data?.TotalRecordsCount ?? 1) -
          (state.totalPages - 1) * state.pageSize;
        if (action.payload.data.Messages.length > 0) {
          toast.info(action.payload.data.Messages[0].Message);
        }
      })
      .addCase(searchLogs.rejected, (state, action) => {
        state.loadingLogs = false;
        if (!action.meta.aborted) {
          toast.error(
            <div>
              Houston, we have a problem.
              <br />
              {action.error.message}
            </div>
          );
        }
      })
      .addCase(enableLineNumbers.fulfilled, (state, action) => {
        console.log('action :>> ', action);
        toast.success(
          `Line number logging enabled for project ${action.meta.arg.name}`
        );
      })
      .addCase(enableLineNumbers.rejected, (state, action) => {
        toast.error(
          <div>
            Houston, we have a problem.
            <br />
            {action.error.message}
          </div>
        );
      });
  }
});

export const {
  instanceUrlChanged,
  authTokenChange,
  usernameChanged,
  filtersChanged,
  loginDomainChanged,
  pageSizeChanged
} = logSlice.actions;

export const selectInstanceUrl = state => state.log.instanceUrl;
export const selectAuthToken = state => state.log.authToken;
export const selectUsername = state => state.log.username;
export const selectIsLogRequestActive = state =>
  new Date() > new Date(state.log.logRequestData.StartTime) &&
  new Date() < new Date(state.log.logRequestData.EndTime);
export const selectLogRequestId = state => state.log.logRequestData.Id;
export const selectLogs = state => state.log.logs;
export const selectLoadingLogs = state => state.log.loadingLogs;
export const selectLoadingStartLogs = state => state.log.loadingStartLogs;
export const selectLoadingStopLogs = state => state.log.loadingStopLogs;
export const selectLoadingLogout = state => state.log.loadingLogout;
export const selectTotalPages = state => state.log.totalPages;
export const selectPageSize = state => state.log.pageSize;
export const selectLastPageRemainder = state => state.log.lastPageRemainder;

export default logSlice.reducer;
