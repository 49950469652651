import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { deleteLogRequest, selectLoadingStopLogs } from '../../features/log/logSlice';

const DisableLoggingModal = props => {
  const loadingStopLogs = useSelector(selectLoadingStopLogs);
  const dispatch = useDispatch();

  const handleDisableLogging = () => {
    dispatch(deleteLogRequest()).then(res => {
      props.toggleModal();
    });
  };

  return (
    <Modal className='DisableLoggingModal' isOpen={props.showModal} centered>
      <ModalHeader toggle={props.toggleModal}>Disable Logging</ModalHeader>
      <ModalBody>
        <p>Are you sure you wish to disable logging for this org?</p>
      </ModalBody>
      <ModalFooter>
        <button className='btn' onClick={props.toggleModal}>
          No
        </button>
        <button className='btn btn-primary' onClick={handleDisableLogging}>
        {loadingStopLogs ? (
          <div className='position-relative'>
            <span className='invisible'>
              Yes
            </span>
            <div
              className='spinner-border spinner-border-sm text-white position-absolute'
              role='status'
              style={{left: '20%', top: '16%'}}
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          'Yes'
        )}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default DisableLoggingModal;
