import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authTokenChange,
  instanceUrlChanged,
  searchLogs,
  selectAuthToken,
  selectInstanceUrl,
  selectIsLogRequestActive,
  selectLoadingLogs,
  selectPageSize
} from '../../features/log/logSlice';
import DisableLoggingModal from '../DisableLoggingModal/DisableLoggingModal';
import EnableLoggingModal from '../EnableLoggingModal/EnableLoggingModal';

const AuthForm = () => {
  const [showEnableLoggingModal, setShowEnableLoggingModal] = useState(false);
  const [showDisableLoggingModal, setShowDisableLoggingModal] = useState(false);
  const dispatch = useDispatch();
  const instanceUrl = useSelector(selectInstanceUrl);
  const authToken = useSelector(selectAuthToken);
  const loadingLogs = useSelector(selectLoadingLogs);
  const IsLogRequestActive = useSelector(selectIsLogRequestActive);
  const pageSize = useSelector(selectPageSize);

  const handleGetLogs = () => {
    dispatch(
      searchLogs({
        PageNumber: 1,
        PageSize: pageSize,
        SortOrder: 'ASC'
      })
    );
  };

  return (
    <div className='AuthForm row'>
      <div className='col-md-6 col-lg-5 col-xl-4'>
        <div className='row'>
          <label
            className='col-md-4 col-lg-3 col-form-label text-nowrap'
            htmlFor='instanceUrl'
          >
            Instance URL
          </label>
          <div className='col-md-8 col-lg-9'>
            <input
              className='form-control'
              type='text'
              id='instanceUrl'
              value={instanceUrl}
              onChange={event =>
                dispatch(instanceUrlChanged(event.target.value))
              }
            />
          </div>
        </div>
        <div className='row mt-2'>
          <label
            className='col-md-4 col-lg-3 col-form-label text-nowrap'
            htmlFor='authToken'
          >
            Auth Token
          </label>
          <div className='col-md-8 col-lg-9'>
            <input
              className='form-control'
              type='text'
              id='authToken'
              value={authToken}
              onChange={event => dispatch(authTokenChange(event.target.value))}
            />
          </div>
        </div>
      </div>
      <div className='ms-auto col-md-6 col-lg-5 col-xl-4'>
        <div className='row justify-content-end me-auto'>
          {IsLogRequestActive ? (
            <button
              className='col-md-5 btn btn-outline-secondary text-nowrap'
              onClick={() =>
                setShowDisableLoggingModal(!showDisableLoggingModal)
              }
            >
              Disable Logging
            </button>
          ) : (
            <button
              className='col-md-5 btn btn-outline-secondary text-nowrap'
              onClick={() => setShowEnableLoggingModal(!showEnableLoggingModal)}
            >
              Enable Logging
            </button>
          )}
        </div>
        <div className='row justify-content-end me-auto my-2'>
          <button
            className='col-md-5 btn btn-outline-secondary text-nowrap'
            onClick={handleGetLogs}
          >
            {loadingLogs ? (
              <div
                className='spinner-border spinner-border-sm text-secondary'
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            ) : (
              'Get Logs'
            )}
          </button>
        </div>
      </div>
      <EnableLoggingModal
        showModal={showEnableLoggingModal}
        toggleModal={() => setShowEnableLoggingModal(!showEnableLoggingModal)}
      />
      <DisableLoggingModal
        showModal={showDisableLoggingModal}
        toggleModal={() => setShowDisableLoggingModal(!showDisableLoggingModal)}
      />
    </div>
  );
};

export default AuthForm;
